























import { format } from "date-fns"
import { Component, Prop, Vue } from "vue-property-decorator"
import { asCurrency } from "@/utils"

@Component({
	name: "DiffCard",
})
export default class extends Vue {
	@Prop() readonly diff!: any

	private getChangeClass(change) {
		if (change.old == null || change.old === "") return "text-info"
		return "text-danger"
	}

	private display(field, val) {
		if (val == null) return "None"
		if (val === "") return "None"
		if (val === true) return "✔"
		if (val === false) return "✘"
		if (field === "Budget") return asCurrency(val)
		return val
	}

	get date() {
    return format(new Date(this.diff.date), "PP pp")
	}
}

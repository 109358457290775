

















import { ref as _ref } from "@vue/composition-api";
import { format } from "date-fns";
import router from "@/router";
import Api from "@/select2/campaign";
import DiffCard from "./components/DiffCard.vue";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const routeId = Number(router.currentRoute.params.id);
  const defaultCampaignHistory = {
    name: "",
    changes: [],
    line_items: [],
    creatives: []
  };

  let campaign = _ref(({ ...defaultCampaignHistory
  } as any));

  let loading = _ref(true);

  async function load() {
    campaign.value = await Api.action(routeId, "history");
    loading.value = false;
  }

  load();
  return {
    campaign,
    loading
  };
};

__sfc_main.components = Object.assign({
  DiffCard
}, __sfc_main.components);
export default __sfc_main;

import type { Lookup } from "./base"
import Generate from "./api/crud"
import { ListQuery, RouterQuery } from "./api/base"
import { queryToList } from "@/utils"

export enum CampaignStatus {
	DRAFT = "DRAFT",
	PENDING = "PENDING",
	MISSING_CREATIVES = "MISSING_CREATIVES",
	DECLINED = "DECLINED",
	ACTIVE = "ACTIVE",
	PAUSED = "PAUSED",
	ENDED = "ENDED",
}

export const CampaignStatusDisplay: Map<CampaignStatus, string> = new Map([
	[CampaignStatus.DRAFT, "Draft"],
	[CampaignStatus.PENDING, "Pending"],
	[CampaignStatus.DECLINED, "Declined"],
	[CampaignStatus.MISSING_CREATIVES, "Missing Creatives"],
	[CampaignStatus.ACTIVE, "Active"],
	[CampaignStatus.PAUSED, "Paused"],
	[CampaignStatus.ENDED, "Ended"],
])

export const CampaignType = {
	DISPLAY: "DISPLAY",
	VIDEO: "VIDEO",
} as const

export type CampaignType = typeof CampaignType[keyof typeof CampaignType]

/** Campaign model with some nullable values for create form */
export interface Campaign {
	id: number | null
	agency?: Lookup | number
	name: string
	advertiser: Lookup | number | null
	status: CampaignStatus
	start_date: Date | string
	end_date: Date | string | null
	budget: number
	type: CampaignType | null
	active_days: number[]
	is_auto: boolean | null
	starting_hour: string
	ending_hour: string
	frequency_cap: number
	frequency_cap_duration: number
	line_item_frequency_cap: number
	line_item_frequency_cap_duration: number
	notes: string
	pacing: number
	locations: number[]
	cpm_goal: number | null
	owner?: Lookup
	pending_changes?: boolean
	submitter?: number | null
	custom_budgeting: boolean
}

export interface CampaignData extends Campaign {
	id: number
	agency: Lookup | number
	advertiser: Lookup | number
	start_date: Date
	end_date: Date
	type: CampaignType
	is_auto: boolean
}

/** Campaign-specific API parameters */
export class Params extends ListQuery {
	status: number[] = []

	constructor(routerQuery: RouterQuery) {
		super(routerQuery)
		this.status = queryToList(routerQuery.status).map((s) => parseInt(s))
	}

	toRouterQuery() {
		return {
			...super.toRouterQuery(),
			status: this.status.map((n) => n.toString()),
		}
	}
}

/** Default camapaign values */
export const defaultCampaign: Campaign = {
	id: null,
	name: "",
	advertiser: null,
	status: CampaignStatus.DRAFT,
	start_date: "",
	end_date: null,
	budget: 0,
	type: null,
	active_days: [],
	is_auto: null,
	starting_hour: "",
	ending_hour: "",
	frequency_cap: 3,
	frequency_cap_duration: 3,
	line_item_frequency_cap: 1,
	line_item_frequency_cap_duration: 1,
	notes: "",
	pacing: 1,
	locations: [],
	cpm_goal: null,
	custom_budgeting: false,
}

export default Generate<CampaignData, CampaignData>("/campaigns")
